<template>
  <div>
    <a-modal :visible="visible" :closable="false" on-ok="handleOk" centered>
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          CANCELAR
        </a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
          INACTIVAR ENTIDADES
        </a-button>
      </template>
      <div class="text-center">
        <h3 class="text-2xl text-blue-dark"><b>Inactivar entidades</b></h3>
        <p>¿Está seguro de que desea inactivar las {{companies.length}} entidades seleccionadas?</p>
        <br>
        <p>Recuerde que para deshacer esta acción deberá activar las entidades de forma individual.</p>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    companies: [],
    visible: Boolean,
    data: []
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    async handleOk(e) {
      const inactivations = {
        companies: this.$props.companies
        }
      this.loading = true;
      let {error, data} = await this.$api.inactivateMassiveCompanys(inactivations);
      this.loading = false;

      if (error) this.showToast('error', 'Error inactivando masivamente las entidades.');

      if (data) {
        this.showToast('success', 'Inactivación masiva éxitosa.');
        this.$emit('toggle-modal', {toggle: false, reload: true });
      }
    },
    handleCancel(e) {
      this.$emit('toggle-modal', {toggle: false, reload: false });
    },
    showToast(result = 'info', msg) {
      this.$notification[result]({
                message: 'Resultado',
                description: msg,
                onClick: () => {},
            });
    }
  },
};
</script>
