import { render, staticRenderFns } from "./Management.vue?vue&type=template&id=3e5d0b16"
import script from "./Management.vue?vue&type=script&lang=js"
export * from "./Management.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports