<template>
  <div class="w-full">
    <div class="mt-5">
      <ProductivityReportTableHeader
        @fetch-productivity="fetchProductivity"
        :loading="loading"
        :query="start_query"
      />
    </div>
    <div
      v-if="!loading"
      class="relative sm:py-8 sm:px-8 sm:bg-white sm:mx-auto sm:h-full rounded-xl shadow-xl py-0 px-0 border border-gray-300 search-complaint"
    >
      <div class="flex justify-between align-center">
        <h3 class="font-bold sm:text-xl text-base text-left">
          Información de las Quejas
        </h3>

        <a-menu mode="horizontal">
          <a-sub-menu>
            <a-button
              slot="title"
              type="primary"
              style="margin-bottom: 16px"
              @click="collapsed = !collapsed"
            >
              <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
            </a-button>
            <a-menu-item>
              <vue-excel-xlsx
                :loading="loadingReport"
                :data="data"
                :columns="columnsExcel"
                file-name="Reporte-productividad"
                sheetName="Listado"
              >
                <span class="mx-2">Descargar vista actual XLSX</span>
              </vue-excel-xlsx></a-menu-item
            >
            <a-menu-item @click="onGetAllReport(false)">
              <vue-excel-xlsx
                :async="true"
                :loading="loadingReport"
                :data="json_data"
                :columns="columnsExcel"
                file-name="Reporte-productividad(Completo)"
                sheetName="Listado"
              >
                <span class="mx-2">Descargar reporte completo XLSX</span>
              </vue-excel-xlsx>
            </a-menu-item>
            <a-menu-item @click="onGetAllReport(true)">
              <vue-excel-xlsx
                :async="true"
                :loading="loadingReport"
                :data="json_csv"
                :columns="columnsExcel"
                file-type="csv"
                file-name="Reporte-productividad(Completo)"
                sheetName="Listado"
              >
                <span class="mx-2">Descargar reporte completo CSV</span>
              </vue-excel-xlsx></a-menu-item
            >
          </a-sub-menu>
        </a-menu>
      </div>
      <div
        class="w-full overflow-x-auto rounded-xl border border-gray-300 productivity"
      >
        <a-table
          class="bg-white"
          :columns="columns"
          rowKey="id"
          :data-source="data"
          :pagination="false"
        >
          <div slot="actions" slot-scope="text, record">
            <button
              class="flex justify-center col-span-1 text-xs pr-2 text-blue h-min"
              @click="complaintDetails(record)"
            >
              <a-icon
                type="eye"
                theme="twoTone"
                two-tone-color="#3366cc"
                :style="{
                  fontSize: '20px',
                }"
              />
              <b class="my-auto ml-2">Ver</b>
            </button>
          </div>
        </a-table>
      </div>
      <div class="mt-4 flex justify-end">
        <a-pagination
          v-model="page"
          :page-size="pageSize"
          :page-size-options="pageSizeOptions"
          :total="count"
          show-size-changer
          size="large"
          @showSizeChange="onShowSizeChange"
        />
      </div>
    </div>
    <div v-else class="absolute w-full justify-center top-1/3 left-0">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>
<script>
import ProductivityReportTableHeader from "@/components/Dashboard/ProductivityReport/ProductivityReportTableHeader";
import ModalConfirmation from "../Companies/ModalConfirmation.vue";
import capitalize from "@/utils/checkForms.js";
const columns = [
  {
    title: "N.° de documento",
    dataIndex: "identification_number",
    key: "identification_number",
  },
  {
    title: "Nombre",
    dataIndex: "full_name",
    key: "full_name",
  },
  {
    title: "Entidad",
    dataIndex: "company_name",
    key: "company_name",
  },
  {
    title: "Id. de radicado",
    dataIndex: "complaint_code",
    ley: "complaint_code",
  },
  {
    title: "Criterio",
    dataIndex: "criterion_name",
    key: "criterion_name",
  },
  {
    title: "Tiempo",
    dataIndex: "evaluation_time",
    key: "evaluation_time",
  },
  {
    title: "Reporte",
    dataIndex: "detail",
    key: "detail",
    scopedSlots: { customRender: "actions" },
  },
];
const columnsExcel = [
  { label: "N.° de documento", field: "identification_number" },
  { label: "Nombre", field: "full_name" },
  { label: "Entidad", field: "company_name" },
  { label: "Id. de radicado", field: "complaint_code" },
  { label: "Criterio", field: "criterion_name" },
  { label: "Tiempo de evaluación", field: "evaluation_time" },
  { label: "Comentarios adicionales", field: "additional_comments" },
];
let data = [];

export default {
  components: {
    ModalConfirmation,
    ProductivityReportTableHeader,
  },
  data() {
    return {
      data,
      columns,
      loading: false,
      page: 1,
      pageSize: 100,
      count: 0,
      showModal: false,
      formQuery: {
        fullname: "",
        identification_type: "",
        identification_number: "",
        company: "",
      },
      collapsed: false,
      actualQuery: "",
      columnsExcel,
      json_data: [],
      json_csv: [],
      pageSizeDownload: 200,
      loadingReport: false,
      start_query: {},
      pageSizeOptions: ["50", "100", "150", "200", "250"],
    };
  },
  created() {
    this.start_query = this.$route.query;
    this.formQuery = this.start_query;

    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
    if (this.$route.query.pageSize) {
      this.pageSize = parseInt(this.$route.query.pageSize);
    } else {
      this.pageSize = 100;
    }
    this.fetchProductivityQuery();
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.$router.replace({
          query: {
            page: this.page,
            pageSize: this.pageSize,
            fullname: this.formQuery.fullname,
            company: this.formQuery.company,
            identification_type: this.formQuery.identification_type,
            identification_number: this.formQuery.identification_number,
          },
        });
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, pageSize: this.pageSize },
        });
      }
    },
    "$route.query.page": function () {
      this.fetchProductivityQuery();
    },
  },
  methods: {
    async setFilterInData(query) {
      this.loading = true;
      let { error, data } = await this.$api.listReproductivity(query);
      this.loading = false;

      if (error) {
        this.data = [];
        this.showToast("error", "Error listando reporte de productividad.");
      }
      if (data) {
        this.data = data.results.map((element) => {
          return {
            ...element,
            company_name: capitalize(element.company_name),
            full_name: capitalize(element.full_name),
          };
        });
        this.count = data.count;
      }
    },
    fetchProductivity(value) {
      this.formQuery = value;
      this.showToast("info", "Buscando resultados...");
      if (1 == this.$route.query.page || !this.$route.query.page) {
        this.$router.replace({
          query: {
            page: this.page,
            fullname: this.formQuery.fullname,
            company: this.formQuery.company,
            identification_type: this.formQuery.identification_type,
            identification_number: this.formQuery.identification_number,
          },
        });
        this.fetchProductivityQuery();
      }
      this.page = 1;
    },
    fetchProductivityQuery() {
      let query = `?page=${this.page}&page_size=${this.pageSize}&`;
      if (this.formQuery.fullname)
        query += `search=${this.formQuery.fullname}&`;
      if (this.formQuery.identification_type)
        query += `identification_type=${this.formQuery.identification_type}&`;
      if (this.formQuery.identification_number)
        query += `identification_number=${this.formQuery.identification_number}&`;
      if (this.formQuery.company) {
        const company = this.$store.state.form.optionsCompanies.find(
          (item) => item.id == this.formQuery.company
        );
        if (company)
          query += `code_company=${company.sfc_code}&type_company=${company.company_type}`;
      }
      this.actualQuery = query;
      this.setFilterInData(query);
    },
    complaintDetails(record) {
      this.$router.push(
        `/dashboard/productivity_report/managment/detail/${record.complaint_code}/${record.criterion_id}`
      );
    },
    onGetAllReport(isCsv = false) {
      // Se realiza una primera petición para determinar el total de registros a descargar
      this.loadingReport = true;

      let pages_all = Math.trunc(this.count / this.pageSizeDownload);
      
      let mod = this.count % this.pageSizeDownload > 0 ? 1 : 0;

      pages_all += mod;
      const promises = [];
      this.json_data = [];

      let query = this.actualQuery.split("&");
      query.splice(0, 2);
      
      for (let i = 0; i < pages_all; i++) {
        let page = `?page=${i + 1}&page_size=${this.pageSizeDownload}&`;
        const query_str = page + query.join("&");
        promises.push(this.$api.listReproductivity(query_str));
      }
      // Ejecutamos todas las peticiones.
      Promise.allSettled(promises)
        .then((response) => {
          let data = [];
          for (let i = 0; i < pages_all; i++) {
            const report = response[i]?.value?.data?.results;
            data = [...data, ...report];
          }

          this.loadingReport = false;
          if (isCsv) this.json_csv = data;
          else this.json_data = data;
        })
        .catch((error) => {
          this.loadingReport = false;
          this.showToast("error", error);
        });
    },
    onShowSizeChange(page, page_size) {
      this.page = page;
      this.pageSize = page_size;
      this.fetchProductivityQuery();
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>
<style>
.productivity .ant-table-thead > tr > th {
  font-weight: bold;
  text-align: center;
  color: #6b7280;
}
</style>
